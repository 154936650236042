import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Helmet from "react-helmet"

import Intro from "../components/page-intro"
import { Destini } from "@tyson-foods/react-component-library"

export default class WhereToBuyPage extends React.Component {
  render() {
    const { data } = this.props 

    return (
      <React.Fragment>
        <Helmet>
          <title>Where to Buy Our Products</title>
          <meta
            name="description"
            content="Find where to buy Raised & Rooted™ products. Pick up a packet of our plant based nuggets, burger, and more at a store near you."
          />
        </Helmet>
        <Layout style={{ height: "80%" }}>
          <Intro
            bgImage="/paper-background.jpg"
            introClass="where-to-buy"
            headingText1="Where to Buy"
            headingColor1="#006B2D"
            bodyColor="#006B2D"
            bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
          />
          <Destini siteID="raisedrooted" />
        </Layout>
      </React.Fragment>
    )
  }
}
